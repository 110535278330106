import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { errorMessages } from 'src/app/constants/errorMessages';
import { WeddingService } from 'src/app/wedding.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss']
})
export class GiftComponent implements OnInit {
  itemId: string | null = '';
  loading = true;
  loadingWedding = true;
  gifts: any[] = [];
  allGifts: any[] = [];
  categories: any[] = [];
  categoryDict: {[key: string]: string} = {}
  submittingGift: number | null = null;
  giftAssigned = false;
  selectedCategory: string = "all";
  wedding: any;

  constructor(
    private route: ActivatedRoute,
    private clipboardApi: ClipboardService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private weddingService: WeddingService
  ) { }

  ngOnInit(): void {
    this.itemId = this.route.snapshot.paramMap.get('id');
    this.weddingService.invitationId = this.itemId;
    this.weddingService.invitationId = this.itemId;
    this.wedding = this.weddingService.wedding;
    if(this.wedding) {
      this.loadingWedding = false;
    }
    this.weddingService.weddingListener.subscribe(wedding => {
      this.wedding = wedding;
      this.loadingWedding = false;
    })
    this.getCategories();
  }

  scrollTo(id: string): void {
    const elementList = document.querySelectorAll('#' + id);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  copyPIX() {
    console.log('copy')
    this.clipboardApi.copy('51986407712');
    this._snackBar.open('Chave PIX copiada!');
  }

  getGifts() {
    this.loading = true
    let url = `${environment.API_URL}/gift/all?weddingId=${environment.WEDDING_ID}`
    this.http.get(url).subscribe((response:any) => {
      if(response.Items?.length > 0) {
        this.allGifts = response.Items;
        this.gifts = response.Items;
        if(this.gifts.some(gift => gift.invitationId == this.itemId)) {
          this.gifts = this.gifts.filter(gift => gift.invitationId == this.itemId);
          this.giftAssigned = true;
        }
        this.loading = false
      }
    }, (error) => {
      this.loading = false
      console.log(error)
    })
  }

  getCategories() {
    let url = `${environment.API_URL}/gift/categories?weddingId=${environment.WEDDING_ID}`
    this.http.get(url).subscribe((response:any) => {
      if(response.Items?.length > 0) {
        this.categories = response.Items;
        this.categories.forEach(category => {
          this.categoryDict[category.categoryId] = category.title;
        })
        this.getGifts();
        // this.categories.forEach(category => {
        //   this.categoryDict[category.categoryId] = category.title;
        // })
      }
    }, (error) => {
      console.log(error)
    })
  }

  assignGift(giftId: string, index: number) {
    if(this.submittingGift !== null) {
      return;
    }

    if(this.gifts.find(gift => gift.giftId == giftId).invitationId) {
      if(this.gifts.find(gift => gift.giftId == giftId).invitationId == this.itemId) {
        this.unassignGift(giftId, index);
      }
      return;
    }

    this.submittingGift = index;
    const body = {
      invitationTitle: this.weddingService.invitation.title
    }
    let url = `${environment.API_URL}/gift/guest?weddingId=${environment.WEDDING_ID}&invitationId=${this.itemId}&giftId=${giftId}`
    this.http.post(url,body).subscribe((response:any) => {
      const gift = this.gifts.find(gift => gift.giftId == giftId)
      gift.invitationId = this.itemId;
      gift.invitationTitle = body.invitationTitle;
      this.gifts = [gift];
      this.submittingGift = null;
      this.giftAssigned = true;
      this._snackBar.open('Presente confirmado!');
    }, (error) => {
      this.submittingGift = null;
      const errorMessage = errorMessages[error.error];
      this._snackBar.open(errorMessage,undefined,{panelClass: 'danger-snack-bar'});
      console.log(error)
    })
  }

  unassignGift(giftId: string, index: number) {
    this.submittingGift = index;
    let url = `${environment.API_URL}/gift/guest?weddingId=${environment.WEDDING_ID}&invitationId=${this.itemId}&giftId=${giftId}`
    this.http.delete(url).subscribe((response:any) => {
      // const gift = this.gifts.find(gift => gift.giftId == giftId)
      this.getGifts();
      this.submittingGift = null;
      this.giftAssigned = false;
      this._snackBar.open('Presente removido!');
    }, (error: any) => {
      this.submittingGift = null;
      const errorMessage = errorMessages[error.error] ?? errorMessages['undefined'];
      console.error(errorMessage);
      this._snackBar.open(errorMessage,undefined,{panelClass: 'danger-snack-bar', duration: 3000});
      console.log(error)
    })
  }

  handleCategoryChange(event: any) {
    if(event == "all") {
      this.gifts = JSON.parse(JSON.stringify(this.allGifts));
    } else {
      this.gifts = this.allGifts.filter(gift => gift.categoryId == event);
      console.log(this.gifts);
    }
  }

}
