import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Renderer2, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WeddingService } from './wedding.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  title = 'application-client';
  itemId: string | null = null

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private weddingService: WeddingService,
    private http: HttpClient,
    private router: Router,
    ) { }
  
  ngOnInit(): void {
    this.itemId = this.weddingService.invitationId;
    this.weddingService.getWedding();
    this.weddingService.invitationListener.subscribe(invitation => {
      this.itemId = invitation.invitationId;
    })
  }

  scrollTo(id: string): void {
    this.handleMobileMenu()
    if(this.router.url.includes('presente') || this.router.url.includes('linkinvalido')) {
      this.router.navigate(['/convite/'+this.itemId])
      setTimeout(() => {
        const elementList = document.querySelectorAll('#' + id);
        const element = elementList[0] as HTMLElement;
        element.scrollIntoView({ behavior: 'smooth' });
      },150)
    } else {
      const elementList = document.querySelectorAll('#' + id);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }
    console.log(this.router.url);
    
  }

  handleMobileMenu(open = false) {
    const classList: string[] = [];
    Object.keys(this.document.body.classList).forEach((key: any) => {
      classList.push(this.document.body.classList[key])
    })
    if(classList.includes('overflow') && classList.includes('offcanvas')) {
      this.renderer.removeClass(this.document.body, 'overflow');
      this.renderer.removeClass(this.document.body, 'offcanvas');
      this.toggleButton.nativeElement.classList.remove('active')
    } else if(open){
      this.renderer.addClass(this.document.body, 'overflow');
      this.renderer.addClass(this.document.body, 'offcanvas');
      this.toggleButton.nativeElement.classList.add('active')
      console.log(this.toggleButton)
    }
  }  

  
}
