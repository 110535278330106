<header id="fh5co-header" class="fh5co-cover" role="banner"
  style="background-image:url(assets/images/capa.jpeg); background-position: center"
  data-stellar-background-ratio="0.5">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2 text-center">
        <div class="display-t">
          <div class="display-tc animate-box" *ngIf="loading.wedding || loading.invitation">
            <div class="loader"></div>
          </div>
          <div class="display-tc animate-box" data-animate-effect="fadeIn"
            *ngIf="invitation?.confirmation == 0 && !loading.wedding && !loading.invitation">
            <h1 class="invitation-title">{{invitation['title']}}</h1>
            <h2>{{invitation.guests.length > 1 ? wedding.messagePlural : wedding.messageSingular}}</h2>
            <div class="simply-countdown simply-countdown-one"></div>
            <!-- <p><a style="cursor:pointer" (click)="scrollTo('fh5co-started')" class="btn btn-default btn-sm">Confirme a sua presença</a></p> -->
            <form class="form-inline">
              <div class="col-md-6 col-sm-6">
                <!-- <button type="button" (click)="submitConfirmation(invitationForm, 1)" class="btn btn-default btn-block" #yesButton>Eu vou =)</button> -->
                <button type="button" [disabled]="submitted.confirming" (click)="handleYesButton()"
                  class="btn btn-primary btn-block" #yesButton
                  [ngClass]="{'button--loading': submitted.confirming}">{{invitation.guests.length > 1 ? 'Vamos sim =)'
                  : 'Eu vou =)'}}</button>
              </div>
              <div class="col-md-6 col-sm-6">
                <button type="button" [disabled]="submitted.denying" (click)="handleNoButton()"
                  style="background-color:grey" class="btn btn-danger btn-block"
                  [ngClass]="{'button--loading': submitted.denying}">{{invitation.guests.length > 1 ?'Não vamos :(' :
                  'Não vou :('}}</button>
              </div>
            </form>
            <b style="color: #fff">{{wedding.messageTerm}}</b>
          </div>
          <div class="display-tc animate-box" data-animate-effect="fadeIn"
            *ngIf="invitation?.confirmation > 0 && !loading.wedding && !loading.invitation">
            <h1 class="invitation-title">{{invitation['title']}}</h1>
            <h2 *ngIf="invitation.confirmation == 2">{{wedding.messageDeny}}</h2>
            <h2 *ngIf="invitation.confirmation == 1">{{wedding.messageConfirm}}</h2>
            <form class="form-inline">
              <div class="col-md-6 col-sm-6">
                <!-- <button type="button" (click)="submitConfirmation(invitationForm, 1)" class="btn btn-default btn-block" #yesButton>Eu vou =)</button> -->
                <button type="button" [disabled]="submitted.confirming" routerLink="/convite/{{itemId}}/presente"
                  class="btn btn-primary btn-block">Presentear</button>
              </div>
              <div class="col-md-6 col-sm-6">
                <button type="button" [disabled]="submitted.denying" (click)="handleConfirmationChange()"
                  style="background-color:grey" class="btn btn-danger btn-block"
                  [ngClass]="{'button--loading': submitted.resetting}">Reconfirmar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div id="fh5co-event" class="fh5co-bg" style="background-image:url(assets/images/img_bg_3.jpg);">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
        <!-- <span>Our Special Events</span> -->
        <h2>Eventos</h2>
      </div>
    </div>
    <div class="row">
      <div class="display-t">
        <div class="display-tc animate-box" *ngIf="loading.wedding">
          <div class="loader"></div>
        </div>
        <div class="display-tc" *ngIf="!loading.wedding">
          <div class="col-md-10 col-md-offset-1 d-flex justify-content-end" *ngFor="let event of wedding.events">
            <div class="col-md-offset-3 col-md-6 col-sm-6 text-center">
              <div class="event-wrap animate-box">
                <h3>{{event.title}}</h3>
                <div class="event-col">
                  <i class="icon-clock"></i>
                  <span>{{event.hour}}</span>
                </div>
                <div class="event-col">
                  <i class="icon-calendar"></i>
                  <span>{{event.date}}</span>
                </div>
                <p><b>Local: </b>{{event.place}}</p>
                <p><b>Endereço: </b>{{event.address}}</p>
                <!-- <p><b>Atenção!</b> Não esqueça de trazer sua máscara</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="fh5co-couple">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
        <h2>Olá!</h2>
        <h3>Segue aqui uma breve apresentação sobre nós dois</h3>
        <!-- <p>We invited you to celebrate our wedding</p> -->
      </div>
    </div>
    <div class="display-tc animate-box" *ngIf="loading.wedding">
      <div class="loader"></div>
    </div>
    <div class="couple-wrap animate-box" *ngIf="!loading.wedding">
      <div class="couple-half">
        <div class="groom">
          <img src="assets/images/jean.jpg" alt="groom" class="img-responsive">
        </div>
        <div class="desc-groom">
          <h3>{{wedding.groom}}</h3>
          <p>{{wedding.groomInfo}}</p>
        </div>
      </div>
      <p class="heart text-center"><i class="icon-heart2"></i></p>
      <div class="couple-half">
        <div class="bride">
          <img src="assets/images/debora.jpg" alt="groom" class="img-responsive">
        </div>
        <div class="desc-bride">
          <h3>{{wedding.bride}}</h3>
          <p>{{wedding.brideInfo}}</p>
        </div>
      </div>
    </div>
  </div>
</div>