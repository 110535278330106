import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http'
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder, Validators
} from '@angular/forms';
import { GuestsModalComponent } from './guests-modal/guests-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DenyConfirmationModalComponent } from './deny-confirmation-modal/deny-confirmation-modal.component';
import { ConfirmationModalData } from './deny-confirmation-modal/deny-confirmation-modal.component'
import { InvitationModel } from '../models/InvitationModel';
import { WeddingService } from '../wedding.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  invitation: any;
  wedding: any;
  loading = {
    invitation: true,
    wedding: true
  }
  submitted = {
    denying: false,
    confirming: false,
    resetting: false
  }
  invitationForm:any
  itemId: string | null = ''
  
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public dialog: MatDialog,
    private weddingService: WeddingService
  ) { }

  ngOnInit(): void {
    this.itemId = this.route.snapshot.paramMap.get('id');
    this.weddingService.invitationId = this.itemId;
    this.wedding = this.weddingService.wedding;
    if(this.wedding) {
      this.loading.wedding = false;
    }
    this.invitation = this.weddingService.invitation;
    if(this.invitation) {
      this.loading.invitation = false;
    }
    this.weddingService.invitationListener.subscribe(invitation => {
      this.invitation = invitation;
      this.loading.invitation = false;
    })
    this.weddingService.weddingListener.subscribe(wedding => {
      this.wedding = wedding;
      this.loading.wedding = false;
    })
  }  

  confirmInvitation(guests:any) {
    this.submitted.confirming = true;

    const body = {
      guests
    }
  
    this.http.post(`${environment.API_URL}/invitation/confirm?weddingId=${environment.WEDDING_ID}&invitationId=${this.itemId}`, body, {
      observe: 'response'
    })
      .subscribe(
        (result: HttpResponse<any>) => {
          this.submitted.confirming = false;
          this.invitation.confirmation = 1;
          console.log(result)
        },
        error => {
            console.log(error);
            this.submitted.confirming = false;
        });
  }

  denyInvitation() {
    this.submitted.denying = true;
  
    this.http.post(`${environment.API_URL}/invitation/deny?weddingId=${environment.WEDDING_ID}&invitationId=${this.itemId}`, null, {
      observe: 'response'
    })
      .subscribe(
        (result: HttpResponse<any>) => {
          this.submitted.denying = false;
          console.log(result)
          this.invitation.confirmation = 2;
        },
        error => {
            console.log(error);
            this.submitted.denying = false;
        });
  }

  resetInvitation() {
    this.submitted.resetting = true;
  
    this.http.post(`${environment.API_URL}/invitation/reset?weddingId=${environment.WEDDING_ID}&invitationId=${this.itemId}`, null, {
      observe: 'response'
    })
      .subscribe(
        (result: HttpResponse<any>) => {
          this.submitted.resetting = false;
          console.log(result)
          this.invitation.confirmation = 0;
          this.loading.invitation = true;
          this.weddingService.getInvitation();
          // this.router.navigate(['/convite/'+this.itemId+'/presentes'])
        },
        error => {
            console.log(error);
            this.submitted.resetting = false;
        });
  }

  handleYesButton() {
    if(this.invitation.guests.length > 1) {
      const dialogRef = this.dialog.open(GuestsModalComponent, {
        data: {guests: this.invitation.guests},
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.confirmInvitation(result.guests)
        }
      });
    } else {
      const guests = this.invitation.guests
      guests[0].selected = true;
      this.confirmInvitation(guests)
    }
  }

  handleNoButton() {
    const dialogRef = this.dialog.open(DenyConfirmationModalComponent, {
      data: {
        title: 'Tem certeza?',
        confirmButton: `Não ire${this.invitation?.guests.length > 1 ? 'mos' : 'i'}`,
        cancelButton: 'Cancelar'
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.denyInvitation();
      }
    });
  }

  handleConfirmationChange() {
    const dialogRef = this.dialog.open(DenyConfirmationModalComponent, {
      data: {
        title: 'Tem certeza?',
        confirmButton: `Reconfirmar`,
        cancelButton: 'Cancelar'
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.resetInvitation();
      }
    });
  }

  
}
