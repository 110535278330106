import { Component, OnInit } from '@angular/core';
import { WeddingService } from '../wedding.service';

@Component({
  selector: 'app-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.scss']
})
export class InvalidLinkComponent implements OnInit {
  wedding: any;
  loading = {
    invitation: true,
    wedding: true
  }

  constructor(
    private weddingService: WeddingService
  ) { }

  ngOnInit(): void {
    this.wedding = this.weddingService.wedding;
    if(this.wedding) {
      this.loading.wedding = false;
    }
    this.weddingService.weddingListener.subscribe(wedding => {
      this.wedding = wedding;
      this.loading.wedding = false;
    })
  }

  

}
