import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { GiftComponent } from './invitation/gift/gift.component';
import { InvitationComponent } from './invitation/invitation.component';

const routes: Routes = [
  {path: 'convite/:id', component: InvitationComponent},
  {path: 'convite/:id/presente', component: GiftComponent},
  {path: '**', component: InvalidLinkComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
