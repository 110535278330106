import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmationModalData {
  title: string;
  message?: string;
  confirmButton: string;
  cancelButton: string;
}
@Component({
  selector: 'app-deny-confirmation-modal',
  templateUrl: './deny-confirmation-modal.component.html',
  styleUrls: ['./deny-confirmation-modal.component.scss']
})
export class DenyConfirmationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<DenyConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData,
  ) {
    console.log(data)
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

}
