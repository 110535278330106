<header id="fh5co-header" class="fh5co-cover" role="banner"
    style="background-image:url(assets/images/capa.jpeg); background-position: center"
    data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center">
                <div class="display-t">
                    <div class="display-tc animate-box" data-animate-effect="fadeIn">
                        <!-- <h1 class="invitation-title">{{invitation['title']}}</h1> -->
                        <h2>Link inválido :/</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div id="fh5co-event" class="fh5co-bg" style="background-image:url(assets/images/img_bg_3.jpg);">
    <div class="overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
          <!-- <span>Our Special Events</span> -->
          <h2>Eventos</h2>
        </div>
      </div>
      <div class="row">
        <div class="display-t">
          <div class="display-tc animate-box" *ngIf="loading.wedding">
            <div class="loader"></div>
          </div>
          <div class="display-tc" *ngIf="!loading.wedding">
            <div class="col-md-10 col-md-offset-1 d-flex justify-content-end" *ngFor="let event of wedding.events">
              <div class="col-md-offset-3 col-md-6 col-sm-6 text-center">
                <div class="event-wrap animate-box">
                  <h3>{{event.title}}</h3>
                  <div class="event-col">
                    <i class="icon-clock"></i>
                    <span>{{event.hour}}</span>
                  </div>
                  <div class="event-col">
                    <i class="icon-calendar"></i>
                    <span>{{event.date}}</span>
                  </div>
                  <p><b>Local: </b>{{event.place}}</p>
                  <p><b>Endereço: </b>{{event.address}}</p>
                  <!-- <p><b>Atenção!</b> Não esqueça de trazer sua máscara</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div id="fh5co-couple">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
          <h2>Olá!</h2>
          <h3>Segue aqui uma breve apresentação sobre nós dois</h3>
          <!-- <p>We invited you to celebrate our wedding</p> -->
        </div>
      </div>
      <div class="display-tc animate-box" *ngIf="loading.wedding">
        <div class="loader"></div>
      </div>
      <div class="couple-wrap animate-box" *ngIf="!loading.wedding">
        <div class="couple-half">
          <div class="groom">
            <img src="assets/images/jean.jpg" alt="groom" class="img-responsive">
          </div>
          <div class="desc-groom">
            <h3>{{wedding.groom}}</h3>
            <p>{{wedding.groomInfo}}</p>
          </div>
        </div>
        <p class="heart text-center"><i class="icon-heart2"></i></p>
        <div class="couple-half">
          <div class="bride">
            <img src="assets/images/debora.jpg" alt="groom" class="img-responsive">
          </div>
          <div class="desc-bride">
            <h3>{{wedding.bride}}</h3>
            <p>{{wedding.brideInfo}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>