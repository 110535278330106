import { HttpClient } from '@angular/common/http';
import { Injectable, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeddingService {
  _invitationId: string | null = null;
  _invitation: any;
  _wedding: any;

  @Output() invitationListener: EventEmitter<any> = new EventEmitter();
  @Output() weddingListener: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  get invitationId() {
    return this._invitationId
  }
  
  set invitationId(id) {
    if(this._invitationId != id) {
      setTimeout(() => {
        this.getInvitation();
      },100);
    }
    this._invitationId = id;
  }
  
  get invitation() {
    return this._invitation;
  }
  
  set invitation(item) {
    this._invitation = item;
  }
  
  get wedding() {
    return this._wedding;
  }
  
  set wedding(item) {
    this._wedding = item;
  }

  getInvitation() {
    let url = `${environment.API_URL}/invitation/?weddingId=${environment.WEDDING_ID}&invitationId=${this._invitationId}`
    this.http.get(url).subscribe((response: any) => {
      this.invitation = response;
      this.invitationListener.emit(response);
    }, (error) => {
      console.log(error)
      if(error.status == 404) {
        this.router.navigate(['/invalid-link'])
      }
    })
  }

  getWedding() {
    let url = `${environment.API_URL}/wedding/info?id=${environment.WEDDING_ID}`
    this.http.get(url).subscribe((response: any) => {
      this._wedding = response;
      this.weddingListener.emit(response);
    }, (error) => {
      console.log(error)
    })
  }

}
