<div class="container">
    <h3>Confirme os convidados</h3>
    <form>
        <div class="checkbox">
            <label>
                <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectAll" (ngModelChange)="handleSelectAll()"><span>Selecionar todos</span>
            </label>
        </div>
        <br>
        <div class="checkbox" *ngFor="let guest of data.guests">
            <label>
                <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="guest.selected"><span>{{guest.name}}</span>
            </label>
        </div>
    </form>
    <div mat-dialog-actions>
        <button mat-button [disabled]="disableConfirm" (click)="confirm()" class="btn btn-primary btn-block">Confirmar</button>
        <button mat-button mat-dialog-close class="btn btn-secondary btn-block">Cancelar</button>
    </div>
</div>