import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface Guest {
  name: string;
  selected: boolean
}

export interface GuestsData {
  guests: Guest[]
}

@Component({
  selector: 'app-guests-modal',
  templateUrl: './guests-modal.component.html',
  styleUrls: ['./guests-modal.component.scss']
})
export class GuestsModalComponent {
  selectAll = false;

  constructor(
    public dialogRef: MatDialogRef<GuestsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GuestsData,
  ) {  }

  confirm(): void {
    this.dialogRef.close(this.data);
  }

  handleSelectAll() {
    // this.selectAll = !this.selectAll;

    this.data.guests.forEach((guest: Guest) => {
      guest.selected = this.selectAll;
    })
  }
  
  get disableConfirm() {
    return !this.data.guests.some((guest: Guest) => guest.selected);
  }
}
