import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InvitationComponent } from './invitation/invitation.component';
import { GuestsModalComponent } from './invitation/guests-modal/guests-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DenyConfirmationModalComponent } from './invitation/deny-confirmation-modal/deny-confirmation-modal.component';
import { GiftComponent } from './invitation/gift/gift.component';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';

@NgModule({
  declarations: [
    AppComponent,
    InvitationComponent,
    GuestsModalComponent,
    DenyConfirmationModalComponent,
    GiftComponent,
    InvalidLinkComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 1500, panelClass: 'wedding-snack-bar'}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
