<!-- <div class="fh5co-loader"></div> -->

<div id="page">
    <a (click)="handleMobileMenu(true)" #toggleButton class="js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white"><i></i></a>
    <div id="fh5co-offcanvas">
        <ng-container *ngTemplateOutlet="menuUl"></ng-container>
    </div>
    <nav class="fh5co-nav" role="navigation">
        <div class="container">
        <div class="row">
            <div class="col-8 col-md-3 col-xl-2">
                <div id="fh5co-logo"><a style="cursor:pointer" (click)="scrollTo('fh5co-header')">Jean & Debora</a></div>
            </div>
            <div class="col-4 col-md-9 col-xl-10 text-right menu-1">
                <ng-container *ngTemplateOutlet="menuUl"></ng-container>
            </div>
        </div>
    
        </div>
    </nav>

    <router-outlet></router-outlet>

    <footer id="fh5co-footer" role="contentinfo">
        <div class="container">
    
          <div class="row copyright">
            <div class="col-md-12 text-center">
              <p>
                <small class="block">&copy; 2023 Jean & Debora.</small>
                <small class="block">Designed by <a href="https://www.instagram.com/jeangsm/" target="_blank">Jean Medeiros</a></small>
              </p>
            </div>
          </div>
    
        </div>
      </footer>
</div>
    
<div class="gototop js-top">
    <a href="#" class="js-gotop"><i class="icon-arrow-up"></i></a>
</div>

<ng-template #menuUl>
    <ul>
      <li><a style="cursor:pointer" (click)="scrollTo('fh5co-header')">Confirmação</a></li>
      <li><a style="cursor:pointer" (click)="scrollTo('fh5co-event')">Eventos</a></li>
    <li><a style="cursor:pointer" (click)="scrollTo('fh5co-couple')">Sobre nós</a></li>
    <li><a style="cursor:pointer" (click)="handleMobileMenu()" routerLink="/convite/{{itemId}}/presente">Presentear</a></li>
    </ul>
</ng-template>