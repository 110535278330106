<header id="fh5co-header" class="fh5co-cover" role="banner" style="background-image:url(assets/images/capa.jpeg); background-position: center" data-stellar-background-ratio="0.5">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2 text-center">
        <div class="display-t">
          <div class="display-tc animate-box" *ngIf="loadingWedding"><div class="loader" ></div></div>
          <div class="display-tc animate-box" data-animate-effect="fadeIn" *ngIf="!loadingWedding">
            <h1 class="invitation-title">Presentear</h1>
            <h2>{{wedding.messageGift}}</h2>
            <p style="color: #fff">
              <b>Chave PIX:</b> {{wedding.pixKey}}<br>
              <b>Jean Gabriel da Silva Medeiros</b>
            </p>
            <form class="form-inline">
              <div class="col-md-6 col-sm-6">
                <button type="button" (click)="copyPIX()" class="btn btn-primary btn-block" #yesButton>Copiar chave PIX</button>
              </div>
              <div class="col-md-6 col-sm-6">
                <button type="button"  style="background-color:grey" class="btn btn-danger btn-block" routerLink="/convite/{{itemId}}">Voltar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div id="fh5co-gallery" class="fh5co-section-gray">
  <div class="display-tc animate-box" *ngIf="loadingWedding"><div class="loader" ></div></div>
  <div class="container" *ngIf="!loadingWedding">
    <div class="row">
      <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
        <h2>Lista de Presentes</h2>
        <p *ngIf="!giftAssigned ">{{wedding.messageSelectGift}}</p>
        <p *ngIf="giftAssigned">{{wedding.messageGiftSelected}}</p>
        <select class="form-control" [(ngModel)]="selectedCategory" (ngModelChange)="handleCategoryChange($event)" *ngIf="!giftAssigned">
          <option value="all">Todas as categorias</option>
          <option *ngFor="let category of categories" [value]="category.categoryId">{{category.title}}</option>
        </select>
      </div>
    </div>
    <div class="row row-bottom-padded-md">
      <div class="col-md-12">
        <div class="display-tc animate-box" *ngIf="loading"><div class="loader" ></div></div>
        <ul id="fh5co-gift-list" *ngIf="!loading">
          <li *ngFor="let gift of gifts; let index = index" class="one-third animate-box" data-animate-effect="fadeIn"
            [ngClass]="{'gift-confirmated': gift.invitationId, 'button--loading': submittingGift == index, 'gift-assigned': gift.invitationId == itemId}">
            <a (click)="assignGift(gift.giftId, index)">
              <div class="gift-content">
                <div class="gift-infos">
                  <span>{{categoryDict[gift.categoryId]}}</span>
                  <h2>{{gift.title}}</h2>
                </div>
                <span *ngIf="gift.invitationId == itemId" class="remove-gift">X</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
